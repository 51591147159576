<template>
  <van-config-provider :theme-vars="themeVars">
    <div>
      <van-nav-bar
          title="任务中心"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
      />
      <div class="homeCard isMe"><!--去掉homeCard或isMe没有小人没有背景白色皇冠变小-->
        <div class="isMoneyIntroduce"><!--去掉isMoneyIntroduce没有小人没有背景白色-->
          <div class="align-center row-between"><!--row-between去掉右边昵称免费次数到下一行了；align-center去掉水平不居中-->
            <div class="headImg"/><!--小人图像-->
            <div class="row-between flex1"><!--去掉flex1右对齐 去掉row-between皇冠到第三行-->
              <div>
                <div>
                  {{users.nickname.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}
                </div>
                <div>
                  <span v-if="level == 0">剩余提问次数：{{residualDegree}} 次</span>
                  <span v-else>会员有效期：{{expiretime || '--'}}</span>
                </div>
              </div>
              <!--     level等级0普通用户1皇冠用户     -->
              <span
                  class="iconfont icon-vipMember vipMember"
                  :class="level == 0 ? '' : 'vip'">
          </span>
            </div>
          </div>
        </div>
      </div>
      <van-cell-group border="true">
        <van-cell title="每日任务">
          <template #value>
            <!--<van-notice-bar
                text="领取免费次数：签到1次奖励3次，每日限签1次。" background="#fff"
            />-->
            <van-notice-bar :scrollable="false" background="#fff" style="text-align: right;">
              <van-swipe
                  vertical
                  class="notice-swipe"
                  :autoplay="3000"
                  :touchable="false"
                  :show-indicators="false"
              >
                <van-swipe-item>每天签到一次，奖励3次提问</van-swipe-item>
                <van-swipe-item>每邀请一位新用户奖励3次提问</van-swipe-item>
              </van-swipe>
            </van-notice-bar>
          </template>
        </van-cell>
        <van-cell title="签到领对话次数" icon="bell">

          <template #label>
            <div><span>签到进度 {{checkIn}} / 1</span><span style="margin-left: 10px;color:#f00;">每天签到一次，奖励3次提问</span></div>
          </template>
          <template #value>
            <van-button v-if="checkIn === '0'" plain round type="primary" size="small" @click="makeUserCheckIn" :disabled="disabled">去签到</van-button>
            <van-button v-if="checkIn === '1'" plain round type="primary" size="small" disabled>已签到</van-button>
          </template>
        </van-cell>

        <van-cell title="邀请新朋友" icon="smile">
          <template #label>
            <div><span>分享进度 {{shareTimes}} / 10</span><span style="margin-left: 10px;color:#f00;">每邀请一位新用户奖励3次提问</span></div>
          </template>
          <template #value>
            <van-button plain round type="primary" size="small" @click="toPoster">去邀请</van-button>
          </template>
        </van-cell>

      </van-cell-group>
    </div>
  </van-config-provider>
</template>

<script>
import { ref, reactive, toRefs, onMounted, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { queryOrderType, memberOrder, payOrderFinishQuery } from '@/api/home'
import QRCode from 'qrcodejs2'
import { showNotify } from 'vant'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import exclusive from './images/exclusive.png'
import delay from './images/delay.png'
import infinite from './images/infinite.png'
import kf from './images/kf.png'
import { userCheckIn } from '@/api/login'
import { showToast, showSuccessToast, showFailToast } from 'vant';

export default {
  name: 'mission',
  setup () {
    const onClickLeft = () => history.back();
    const store = useStore()
    const active = ref(1)
    const route = useRoute()
    const router = useRouter()
    let invokeCount = 0
    const state = reactive({
      level: computed(() => store.getters.roles),//用户角色
      users: store.getters.users,//用户名
      expiretime: computed(() => store.getters.expiretime),//会员到期时间
      residualDegree: computed(() => store.getters.residualDegree),//非会员剩余几次
      checkIn: computed(() => store.getters.checkIn),
      shareTimes: computed(() => store.getters.shareTimes),
      posterUrl: computed(() => store.getters.posterUrl),
      disabled: false
    })
    const methods = reactive({
      toPoster(){
        store.dispatch('SetPosterUrl').then(res => {
          router.push('/poster')
        })
      },
      // 获取订单类型 1个月还是多个月
      //保留
      loadingQueryOrderType () {
        queryOrderType().then(res => {
          state.vipMoney = res.data
          state.vipMoneySelected = res?.data[0]?.id
        })
      },
      // 完成支付
      //保留
      finishOrder () {
        state.codeImg = false
        state.userApply = false
        showNotify({ type: 'success', message: '支付成功' })
        methods.refreshGetInfo()
      },
      // 刷新用户信息
      //保留
      refreshGetInfo () {
        state.time888 = new Date()
        store.dispatch('GetInfo').then(() => {})
      },
      // 判断路由变化http://192.168.3.8:2021/#/home?orderId=3066428391642103808&paytype=1
      //保留
      routerChange () {
        if (route.query.orderId && route.query.paytype) {
          state.orderId = route.query.orderId
          methods.userApplyFun()
        }
      },
      // 关闭弹框
      //保留
      handleClose () {
        invokeCount = 0
        methods.cleanTimer()
        state.codeImg = false
        methods.refreshGetInfo()
      },
      //用户主动点击我已支付按钮
      //保留
      userApplyFun () {
        payOrderFinishQuery(state.orderId).then(res => {
          state.userApply = false
          if (res.data) {
            methods.finishOrder()
          } else {
            showNotify({ type: 'warning', message: '支付失败，若已完成支付请联系客服' })
          }
        })
      },
      makeUserCheckIn () {
        userCheckIn().then(res => {
          //console.log(res)//Object { code: 0, message: "成功", data: "签到成功", success: true }
          store.dispatch('GetInfo').then(() => {})
          if(res.code == 0){
            showSuccessToast({
              message:'已为您增加3次对话机会',
              duration:2500
            });
          }
        }).catch((msg) => {
          showFailToast({
            message:msg,
            duration:2500
          });
        })
      }
    })
    onMounted(() => {
      methods.loadingQueryOrderType()
      methods.routerChange()
      /*store.dispatch('GetInfo').then(res => {
        console.log(res.data.checkIn)
        console.log(res.data.shareTimes)
      })*/
      //监听页面状态
      document.addEventListener("visibilitychange", () => {
        if(document.hidden) {
          // 页面被挂起
          state.time888="页面被挂在"
        }
        else {
          //更新用户信息
          methods.refreshGetInfo()
          // 页面呼出
        }
      })
      setInterval(() => {
        const now = new Date();
        const hour = now.getHours();
        const minute = now.getMinutes();
        const second = now.getSeconds();
        if ((hour === 23 && minute === 59 && second === 59) || (hour === 0 && minute === 0 && second === 0)) {
          state.disabled = true;
        } else {
          state.disabled = false;
        }
      }, 1000);
    })
    const themeVars = reactive({
      cellIconSize: '40px',
      noticeBarHeight: '20px',
      noticeBarPadding: '0',
    })
    const shareCount = 1
    const signCount = 1
    return {
      active,
      ...toRefs(state),
      ...toRefs(methods),
      onClickLeft,
      themeVars,
      shareCount,
      signCount
    }
  }
}
</script>
<style scoped lang="less">
@import "./common";
.homeScroll{
  overflow-y: auto;
}
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.notice-swipe {
  height: 20px;
  line-height: 20px;
}

.van-col-1{
  background-color: #5b9ef7;
}

.van-col-2{
  background-color: #3bcb73;
}

</style>
